#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout {
  height: 100vh;
}

p.review-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

}

Card {
  width: 300;
  height: 120;
  margin: 16px; 
  background: red;
}